import React from 'react';
import { loadStylesheet } from '@ps-refarch-ux/mfe-utils';
import SmartGroups from '../components/smart-groups';
loadStylesheet('https://assets.powerschool.com/neon/2.3.0/css/neon-styles.css');
loadStylesheet('https://assets.powerschool.com/neon/unversioned/fonts.css');
import { NeonMessages, DEFAULT_SYSTEM_MESSAGES_FLOATING_ID, DEFAULT_TOAST_MESSAGE_FLOATING_ID } from '@ps-refarch-ux/neon';
import { AuthContext } from '../common/context/auth-context';
import './root-component.scss';
import { DateHelper } from '../common/helper/date-helper';
export function RootComponent(props) {
    return (React.createElement(AuthContext.Provider, { value: {
            authToken: props.config.AUTH_TOKEN,
            timeZone: DateHelper.checkAndGetTimeZone(props.config.TIMEZONE),
            analysisTimePeriod: {
                startDate: props.config.ANALYSIS_TIME_PERIOD.startDate ? DateHelper.getDate(props.config.ANALYSIS_TIME_PERIOD.startDate).toDateString() : '',
                endDate: props.config.ANALYSIS_TIME_PERIOD.endDate ? DateHelper.getDate(props.config.ANALYSIS_TIME_PERIOD.endDate).toDateString() : '',
            }
        } },
        React.createElement("div", { className: 'mfe-beh-smart-groups-system-floating-msg-container' },
            React.createElement(NeonMessages, { id: DEFAULT_SYSTEM_MESSAGES_FLOATING_ID, dataType: "system-floating", className: 'mfe-beh-smart-groups-system-floating-msg' })),
        React.createElement(NeonMessages, { id: DEFAULT_TOAST_MESSAGE_FLOATING_ID, dataType: "toast" }),
        React.createElement(SmartGroups, null)));
}
