import React from 'react';
import { NeonErrorPageContent, NeonButton } from '@ps-refarch-ux/neon';
import './error-state.scss';
export default function ErrorState(_a) {
    var type = _a.type, onRefreshHandler = _a.onRefreshHandler;
    var handleRefreshClick = function () {
        onRefreshHandler();
    };
    return (React.createElement("div", { className: 'mfe-beh-smart-groups-error-state' },
        React.createElement("div", { className: 'mfe-beh-smart-groups-error-msg' },
            React.createElement(NeonErrorPageContent, { dataErrorCode: "500", dataShowErrorCode: false, dataHeading: 'Unknown error', dataSubheading: 'An unknown error has occurred.', dataBody: 'Try again later and contact support if the problem persists.' },
                React.createElement(NeonButton, { id: "mfe-beh-smart-groups-error-reload", dataType: "primary", dataText: "Refresh page", onClick: handleRefreshClick })))));
}
