import React, { useContext } from 'react';
import { showSystemMessage } from '@ps-refarch-ux/neon';
import { NeonModalDialog, NeonButton } from '@ps-refarch-ux/neon';
import { errorMsgForDownload } from '../smart-group-table-config';
import { ModalLoaderContext } from '../../../../common/context/smart-group-context';
import { DownloadModalContent, DownloadModalHeader, successMsgForDownload, ExportMsgHeading } from '../smart-group-table-config';
import { SmartGroupApi } from '../../../../services/api/smart-groups-mfe-api';
import { DEFAULT_SYSTEM_MESSAGES_FLOATING_ID } from '@ps-refarch-ux/neon';
var DownloadSmartGroupDetails = function (_a) {
    var authToken = _a.authToken, smartGroupName = _a.smartGroupName, setExportData = _a.setExportData, exportDataType = _a.exportDataType, smartGroupIdForDownload = _a.smartGroupIdForDownload, filterContext = _a.filterContext, consequenceType = _a.consequenceType;
    var setModalLoaderView = useContext(ModalLoaderContext).setModalLoaderView;
    var downloadSmartGroupFile = function (response) {
        var fileName = exportDataType === 'csv' ? "".concat(smartGroupName, ".csv") : "".concat(smartGroupName, ".xlsx");
        var blobType = exportDataType === 'csv' ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        var blob = new Blob([new Uint8Array(response)], { type: blobType });
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        setModalLoaderView('');
        showSystemMessage(successMsgForDownload(smartGroupName, exportDataType), {
            sendTo: DEFAULT_SYSTEM_MESSAGES_FLOATING_ID,
            messageType: 'success',
            title: ExportMsgHeading,
        });
    };
    var handleContinueClick = function () {
        // set loader to true in common state.
        setModalLoaderView(exportDataType);
        setExportData(false);
        SmartGroupApi.exportSmartGroupData(authToken, exportDataType, smartGroupIdForDownload, consequenceType, filterContext, function (response) {
            downloadSmartGroupFile(response);
        }, function (error) {
            setModalLoaderView('');
            showSystemMessage(errorMsgForDownload(smartGroupName, exportDataType), {
                sendTo: DEFAULT_SYSTEM_MESSAGES_FLOATING_ID,
                messageType: 'error',
                title: ExportMsgHeading,
            });
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(NeonModalDialog, { id: "mfe-beh-smart-groups-modal-dialog-header", neonDialogHasClosed: function () {
                setExportData(false);
            } },
            React.createElement("div", { "data-slot": "dialog-header-title" }, DownloadModalHeader),
            React.createElement("div", { "data-slot": "dialog-body" },
                React.createElement("div", { className: "mfe-beh-smart-groups-smg__dialog_body_wrapper" },
                    React.createElement("span", { className: "mfe-beh-smart-groups-smg__dialog_body" }, DownloadModalContent))),
            React.createElement("div", { "data-slot": "dialog-footer-content" },
                React.createElement("div", { className: "neon-2_3_0-button-layout" },
                    React.createElement(NeonButton, { id: "mfe-beh-smart-groups-modal-dialog-cancel", dataText: "Cancel", dataType: "Default", onClick: function (event) {
                            setExportData(false);
                        } }),
                    React.createElement(NeonButton, { id: "mfe-beh-smart-groups-modal-dialog-confirm", dataText: "Continue", dataType: "primary", onClick: function (event) {
                            event.preventDefault();
                            handleContinueClick();
                        } }))))));
};
export default DownloadSmartGroupDetails;
