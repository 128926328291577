/**
 * Renders a modal dialog component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.type - The type of the modal dialog.
 * @param {function} props.setModalDialog - The function to set the modal dialog state.
 * @param {Array} props.selectedStudents - The array of selected students.
 * @param {function} props.markCompletedConfirmHandler - The function to handle the mark completed confirmation.
 * @param {function} props.deleteStudentHandler - The function to handle the delete student action.
 * @param {string} props.consequenceName - The name of the consequence.
 * @returns {ReactElement} The rendered modal dialog component.
 */
import { NeonButton, NeonModalDialog } from '@ps-refarch-ux/neon';
import { deleteStudent, markCompleted, processStudentNames } from '../helper/common-message-helper';
import React from 'react';
import './modal-dialog.scss';
export default function ModalDialog(_a) {
    var type = _a.type, setModalDialog = _a.setModalDialog, selectedStudents = _a.selectedStudents, actionConfirmHandler = _a.actionConfirmHandler, consequenceName = _a.consequenceName;
    var content = {
        title: '',
        body: '',
        confirmButtonText: '',
        confirmButtonHandler: function (type, setectedStudentEnrollment) { }
    };
    var extractedNames = processStudentNames(selectedStudents, false);
    if (type === 'mark-completed') {
        content = {
            title: 'Mark complete',
            body: markCompleted.body(extractedNames, consequenceName),
            confirmButtonText: 'Confirm',
            confirmButtonHandler: actionConfirmHandler
        };
    }
    if (type === 'delete-student') {
        content = {
            title: 'Delete',
            body: deleteStudent.body(extractedNames, consequenceName),
            confirmButtonText: 'Delete',
            confirmButtonHandler: actionConfirmHandler
        };
    }
    return (React.createElement(NeonModalDialog, { id: "mfe-beh-smart-groups-modal-dialog-header", neonDialogHasClosed: function () {
            setModalDialog('');
        }, "data-testid": "mfe-beh-smart-groups-modal-dialog-header" },
        React.createElement("div", { "data-slot": "dialog-header-title" }, content.title),
        React.createElement("div", { "data-slot": "dialog-body" },
            React.createElement("div", { className: 'mfe-beh-smart-groups-smg__dialog_body_wrapper' },
                React.createElement("span", { className: "mfe-beh-smart-groups-smg__dialog_body" }, content.body))),
        React.createElement("div", { "data-slot": "dialog-footer-content" },
            React.createElement("div", { className: "neon-2_3_0-button-layout" },
                React.createElement(NeonButton, { id: 'mfe-beh-smart-groups-modal-dialog-cancel', dataText: 'Cancel', dataType: 'Default', onClick: function (event) {
                        event.preventDefault();
                        setModalDialog('');
                    } }),
                content.confirmButtonText && React.createElement(NeonButton, { id: 'mfe-beh-smart-groups-modal-dialog-confirm', dataText: content.confirmButtonText, dataType: 'primary', onClick: function (event) {
                        event.preventDefault();
                        setModalDialog('');
                        content.confirmButtonHandler(type, selectedStudents);
                    } })))));
}
