import { createContext } from 'react';
import { InitialValues } from '../constants';
// modal loader context
export var ModalLoaderContext = createContext({
    modalLoaderView: '',
    setModalLoaderView: function (modalLoaderView) { return undefined; }
});
// side panel view context
export var SidePanelViewContext = createContext({
    sidePanelView: '',
    setSidePanelView: function (sidePanelView) { return undefined; }
});
// filter context
export var FilterContext = createContext(InitialValues.FilterContext);
export var GroupsAndstudentsContext = createContext(InitialValues.GroupsAndStudentsContext);
// school year context
export var SchoolYearContext = createContext(InitialValues.SchoolYearData);
export var GradesContext = createContext(InitialValues.GradesContext);
