export var validateInput = function (value, condition, //Not needed for this rule,just keeping same request structure as expected in neon
context //Not needed for this rule,just keeping same request structure as expected in neon
) {
    // Check if the input value contains HTML tags using a regular expression
    var htmlTagsRegex = /<\/?[^>]+(>|$)/;
    if (htmlTagsRegex.test(value)) {
        return {
            isValid: false,
            errorMessage: 'HTML tags are not allowed in this field',
        };
    }
    else {
        return {
            isValid: true,
        };
    }
};
export var validateInputForButtonDisable = function (value) {
    // Check if the input value contains HTML tags using a regular expression
    var htmlTagsRegex = /<\/?[^>]+(>|$)/;
    return value !== undefined && htmlTagsRegex.test(value);
};
