// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mfe-beh-smart-groups-modal-container {
  align-items: center !important;
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  display: flex !important;
  flex-direction: column !important;
  inset-inline-end: 0 !important;
  inset-inline-start: 0 !important;
  justify-content: center !important;
  position: fixed !important;
  top: 0 !important;
  z-index: 10000 !important;
}`, "",{"version":3,"sources":["webpack://./common/components/modal-loader/modal-loader.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,8BAAA;EACA,SAAA;EACA,wBAAA;EACA,iCAAA;EACA,8BAAA;EACA,gCAAA;EACA,kCAAA;EACA,0BAAA;EACA,iBAAA;EACA,yBAAA;AACJ","sourcesContent":[".__mfe__modal-container{\n    align-items: center !important;\n    background: rgba(0,0,0,.4);\n    bottom: 0;\n    display: flex !important;\n    flex-direction: column !important;\n    inset-inline-end: 0 !important;\n    inset-inline-start: 0 !important;\n    justify-content: center !important ;\n    position: fixed !important;\n    top: 0 !important;\n    z-index: 10000 !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
