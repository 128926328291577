// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mfe-beh-smart-groups-system-floating-msg-container {
  position: relative;
}

.mfe-beh-smart-groups-system-floating-msg {
  left: 0;
  right: 0;
  top: auto;
  margin-left: auto;
  margin-right: auto;
  width: 990px;
}

label {
  text-transform: none !important;
}

input {
  border-radius: 0;
  background-color: white;
}

textarea {
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./app/root-component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AACA;EACI,OAAA;EACA,QAAA;EACA,SAAA;EACA,iBAAA;EACA,kBAAA;EACA,YAAA;AAEJ;;AACA;EACI,+BAAA;AAEJ;;AAEA;EACI,gBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,uBAAA;AACJ","sourcesContent":[".__mfe__system-floating-msg-container {\n    position: relative;\n}\n.__mfe__system-floating-msg {\n    left: 0;\n    right: 0;\n    top: auto;\n    margin-left: auto;\n    margin-right: auto;\n    width: 990px;\n}\n// make text-transform: none !important; for all labels to avoid conflicts with node modules label style. common for all labels in this mfe\nlabel {\n    text-transform: none !important;\n}\n\n// for balancing the global css coming from App shell\ninput {\n    border-radius: 0;\n    background-color: white;\n}\n\ntextarea {\n    background-color: white;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
