// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mfe-beh-smart-groups-smart-group-header {
  color: #386D86;
  padding-top: 28.8px;
}

.mfe-beh-smart-groups-smart-group {
  font: var(--neon-2_3_0-font-weight-bold) 48px/30px Roboto, Arial, Helvetica, sans-serif;
}

.mfe-beh-smart-groups-select-smart-group {
  margin-top: var(--neon-2_3_0-spacing-medium) !important;
  width: 234px;
}

.mfe-beh-smart-groups-loading-error-state-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65%;
}`, "",{"version":3,"sources":["webpack://./components/smart-groups/smart-groups.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,mBAAA;AACJ;;AAEA;EACI,uFAAA;AACJ;;AAEA;EACI,uDAAA;EACA,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;AACJ","sourcesContent":[".__mfe__smart-group-header {\n    color: #386D86;\n    padding-top: 28.8px;\n}\n\n.__mfe__smart-group {\n    font: var(--__neon__font-weight-bold) 48px/30px Roboto, Arial, Helvetica, sans-serif;\n}\n\n.__mfe__select-smart-group {\n    margin-top: var(--__neon__spacing-medium) !important;\n    width: 234px;\n}\n\n.__mfe__loading-error-state-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 65%\n}\n\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
