import moment from 'moment-timezone';
var DateHelper = /** @class */ (function () {
    function DateHelper() {
    }
    DateHelper.getDate = function (date) {
        var dateObj = date ? new Date(date) : new Date();
        dateObj.setHours(0, 0, 0, 0);
        return dateObj;
    };
    /**
     * @param timeZone Timezone string
     * @returns Moment object with current date and time in the given timezone
     */
    DateHelper.getMomentDateWithTimeZone = function (timeZone) {
        return moment().tz(timeZone);
    };
    /**
     * @param timeZone Timezone string
     * @returns Timezone string if valid, else browser timezone
     */
    DateHelper.checkAndGetTimeZone = function (timeZone) {
        // If the timezone is not valid, then use the browser timezone
        if (!timeZone || moment.tz.zone(timeZone) === null) {
            return moment.tz.guess();
        }
        return timeZone;
    };
    return DateHelper;
}());
export { DateHelper };
