// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mfe-beh-smart-groups-empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--text-color-dark, #333);
  font: var(--neon-2_3_0-font-3);
}

.mfe-beh-smart-groups-empty-state-illustration {
  width: 300px;
  height: 200px;
}

.mfe-beh-smart-groups-empty-state-heading {
  margin-block-start: 20px;
  font-weight: var(--neon-2_3_0-font-weight-bold) !important;
}

.mfe-beh-smart-groups-empty-state-subheading {
  font-weight: var(--neon-2_3_0-font-weight-normal) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./common/components/empty-state/empty-state.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,mCAAA;EACA,8BAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;AACJ;;AAEA;EACI,wBAAA;EACA,0DAAA;AACJ;;AAEA;EACI,4DAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACJ","sourcesContent":[".__mfe__empty-state {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n    color: var(--text-color-dark, #333);\n    font: var(--__neon__font-3);\n}\n\n.__mfe__empty-state-illustration {\n    width: 300px;\n    height: 200px;\n}\n\n.__mfe__empty-state-heading {\n    margin-block-start: 20px;\n    font-weight: var(--__neon__font-weight-bold) !important;\n}\n\n.__mfe__empty-state-subheading {\n    font-weight: var(--__neon__font-weight-normal) !important;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
