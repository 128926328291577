var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState, useContext } from 'react';
import './smart-groups.scss';
import LoadingState from '../../common/components/loading-state';
import { NeonSelectField } from '@ps-refarch-ux/neon';
import SmartGroupTable from './smart-group-table/smart-group-table';
import { formatGrades, formatGroupsAndStudents, formatSmartGroups } from './smart-groups-config';
import { SmartGroupApi } from '../../services/api/smart-groups-mfe-api';
import ErrorState from '../../common/components/error-state';
import { AuthContext } from '../../common/context/auth-context';
import { ModalLoaderContext, GroupsAndstudentsContext, SidePanelViewContext, SchoolYearContext, GradesContext, FilterContext } from '../../common/context/smart-group-context';
import { InitialValues } from '../../common/constants';
import { DateHelper } from '../../common/helper/date-helper';
import EmptyState from '../../common/components/empty-state';
export default function SmartGroups() {
    var authToken = useContext(AuthContext).authToken;
    var _a = useState(true), loading = _a[0], setloading = _a[1];
    var _b = useState(false), error = _b[0], setError = _b[1];
    var _c = useState(false), refreshToggle = _c[0], setRefreshToggle = _c[1];
    var _d = useState([]), smartGroups = _d[0], setSmartGroups = _d[1];
    var _e = useState(undefined), selectedSmartGroup = _e[0], setSelectedSmartGroup = _e[1];
    // Source for Grades
    var _f = useState(__assign({}, InitialValues.GradesContext)), gradesContext = _f[0], setGradesContext = _f[1];
    // Source for GroupsAndstudentsContext
    var _g = useState(__assign({}, InitialValues.GroupsAndStudentsContext)), groupsAndStudents = _g[0], setgroupsAndStudents = _g[1];
    // Source for SchoolYearContext
    var _h = useState(__assign({}, InitialValues.SchoolYearData)), schoolYearData = _h[0], setSchoolYearData = _h[1];
    // Source for ModalLoaderContext
    var _j = useState(''), modalLoaderView = _j[0], setModalLoaderView = _j[1];
    // Source for SidePanelViewContext
    var _k = useState(''), sidePanelView = _k[0], setSidePanelView = _k[1];
    // Source for Filters
    var _l = useState([]), filterSelectedGrades = _l[0], setFilterSelectedGrades = _l[1];
    var _m = useState(DateHelper.getDate()), filterFromDate = _m[0], setFilterFromDate = _m[1];
    var _o = useState(DateHelper.getDate()), filterToDate = _o[0], setFilterToDate = _o[1];
    var _p = useState(DateHelper.getDate()), filterRosterDate = _p[0], setFilterRosterDate = _p[1];
    var setFilterContext = function (selectedGrades, fromDate, toDate, rosterDate) {
        setFilterSelectedGrades(__spreadArray([], selectedGrades, true));
        setFilterFromDate(fromDate);
        setFilterToDate(toDate);
        setFilterRosterDate(rosterDate);
    };
    useEffect(function () {
        setloading(true);
        setError(false);
        setSmartGroups([]);
        setSelectedSmartGroup(undefined);
        setGradesContext(__assign({}, InitialValues.GradesContext));
        setgroupsAndStudents(__assign({}, InitialValues.GroupsAndStudentsContext));
        setSchoolYearData(__assign({}, InitialValues.SchoolYearData));
        //Fetching Smart Groups data
        SmartGroupApi.getSmartGroup(authToken, function (smartGroups) {
            var formattedSmartGroups = __spreadArray([], formatSmartGroups(smartGroups), true);
            setSmartGroups(formattedSmartGroups);
            setSelectedSmartGroup(formattedSmartGroups[0]);
            setloading(false);
            setError(false);
        }, function (error) {
            setloading(false);
            setError(true);
        });
        // Fetching Groups and Students and School year data to be used in Add student form
        SmartGroupApi.getGroupsAndStudentsAndSchoolYear(authToken, function (groupAndStudents, schoolYearData) {
            setgroupsAndStudents({
                loading: false,
                groupsAndStudents: formatGroupsAndStudents(__spreadArray([], groupAndStudents, true))
            });
            setSchoolYearData(__assign(__assign({}, schoolYearData), { loading: false }));
            setFilterFromDate(DateHelper.getDate(schoolYearData.start_date));
            setFilterToDate(DateHelper.getDate(schoolYearData.end_date));
            setFilterRosterDate(DateHelper.getDate());
        }, function (error) {
            setgroupsAndStudents({
                loading: false,
                groupsAndStudents: []
            });
            setSchoolYearData(__assign(__assign({}, InitialValues.SchoolYearData), { loading: false }));
            setFilterFromDate(DateHelper.getDate());
            setFilterToDate(DateHelper.getDate());
            setFilterRosterDate(DateHelper.getDate());
        });
        // Fetching Grades to be used in Settings
        SmartGroupApi.getGrades(authToken, function (grades) {
            var formattedGrades = formatGrades(__spreadArray([], grades, true));
            setGradesContext({
                loading: false,
                grades: __spreadArray([], formattedGrades, true)
            });
            setFilterSelectedGrades(formattedGrades.map(function (grade) { return grade.name; }));
        }, function (error) {
            setGradesContext({
                loading: false,
                grades: []
            });
            setFilterSelectedGrades([]);
        });
    }, [refreshToggle]);
    var onRefreshHandler = function () {
        setRefreshToggle(!refreshToggle);
    };
    var getSelectedSmartGroup = function (smartGroupValue) {
        return smartGroups.find(function (smartGroup) { return smartGroup.value === smartGroupValue; });
    };
    var handleSmartGroupChange = function (smartGroupValue) {
        setSelectedSmartGroup(getSelectedSmartGroup(smartGroupValue));
    };
    return (React.createElement(ModalLoaderContext.Provider, { value: { modalLoaderView: modalLoaderView, setModalLoaderView: setModalLoaderView } },
        React.createElement(SidePanelViewContext.Provider, { value: { sidePanelView: sidePanelView, setSidePanelView: setSidePanelView } },
            React.createElement(GradesContext.Provider, { value: gradesContext },
                React.createElement(GroupsAndstudentsContext.Provider, { value: groupsAndStudents },
                    React.createElement(SchoolYearContext.Provider, { value: schoolYearData },
                        React.createElement(FilterContext.Provider, { value: { filterSelectedGrades: filterSelectedGrades, filterFromDate: filterFromDate, filterToDate: filterToDate, filterRosterDate: filterRosterDate, todayDate: InitialValues.FilterContext.todayDate, setFilterContext: setFilterContext } },
                            React.createElement("div", { className: 'neon-2_3_0-layout neon-2_3_0-text' },
                                React.createElement("div", { className: 'mfe-beh-smart-groups-smart-group-header ' },
                                    React.createElement("span", { className: 'mfe-beh-smart-groups-smart-group' }, "Smart Groups")),
                                error ? React.createElement("div", { className: 'mfe-beh-smart-groups-loading-error-state-container' },
                                    React.createElement(ErrorState, { type: 'content', onRefreshHandler: onRefreshHandler }),
                                    " ") :
                                    loading ? React.createElement("div", { className: 'mfe-beh-smart-groups-loading-error-state-container' },
                                        React.createElement(LoadingState, null),
                                        " ") : (smartGroups.length ? React.createElement(React.Fragment, null,
                                        React.createElement(NeonSelectField, { id: "mfe-beh-smart-groups-select-smart-group", dataLabelText: "Smart group", dataShowLabel: "true", options: smartGroups, modelValue: selectedSmartGroup === null || selectedSmartGroup === void 0 ? void 0 : selectedSmartGroup.value, modelValueChange: function (smartGroupValue) { handleSmartGroupChange(smartGroupValue); }, className: 'mfe-beh-smart-groups-select-smart-group' }),
                                        React.createElement(SmartGroupTable, { consequenceType: selectedSmartGroup === null || selectedSmartGroup === void 0 ? void 0 : selectedSmartGroup.type, consequenceId: selectedSmartGroup === null || selectedSmartGroup === void 0 ? void 0 : selectedSmartGroup.value, consequenceName: selectedSmartGroup === null || selectedSmartGroup === void 0 ? void 0 : selectedSmartGroup.text })) : React.createElement("div", { className: 'mfe-beh-smart-groups-loading-error-state-container' },
                                        " ",
                                        React.createElement(EmptyState, { type: 'smart-groups' }),
                                        " "))))))))));
}
