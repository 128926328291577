var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { ConsequenceType } from '../../../common/constants';
import { DateHelper } from '../../../common/helper/date-helper';
// // Function to sort data based on the selected column and sorting direction
export var sortTableData = function (data, column, direction) {
    var multiplier = (direction === SORT_ASC) ? 1 : -1;
    return data.slice().sort(function (a, b) {
        switch (column) {
            case 'student name':
                return multiplier * a.last_name.localeCompare(b.last_name);
            case 'grade':
                return multiplier * a.ordinal.localeCompare(b.ordinal);
            case 'date':
                return multiplier * a.assigned_date.localeCompare(b.assigned_date);
            default:
                return data;
        }
    });
};
var TableActionsConfig = /** @class */ (function () {
    function TableActionsConfig() {
    }
    TableActionsConfig.continuousActions = [
        {
            text: 'Mark complete',
            id: 'ac-1'
        }, {
            text: 'Add note',
            id: 'ac-2'
        }, {
            text: 'Delete',
            id: 'ac-3'
        }
    ];
    return TableActionsConfig;
}());
export { TableActionsConfig };
export function getContinuousActionsOptions(onClickofMarkComplete, onClickofAddNotes, onClickofDelete) {
    return ([
        {
            text: 'Mark complete',
            id: 'ac-1',
            onClickCallback: onClickofMarkComplete
        }, {
            text: 'Add note',
            id: 'ac-2',
            onClickCallback: onClickofAddNotes
        }, {
            text: 'Delete',
            id: 'ac-3',
            onClickCallback: onClickofDelete
        }
    ]);
}
export function getFixedActionsOptions(onClickofAddNotes, onClickoDelete) {
    return ([
        {
            text: 'Add note',
            id: 'ac-2',
            onClickCallback: onClickofAddNotes
        }, {
            text: 'Delete',
            id: 'ac-3',
            onClickCallback: onClickoDelete
        }
    ]);
}
export function exportSmartGroupData(onClickOfExportSmartGroup) {
    return ([
        {
            text: 'Export as .csv',
            id: 'export-1',
            onClickCallback: function () { return onClickOfExportSmartGroup('csv'); }
        }, {
            text: 'Export as .xls',
            id: 'export-2',
            onClickCallback: function () { return onClickOfExportSmartGroup('xls'); }
        }
    ]);
}
var ConsequenceRosterTableConfig = /** @class */ (function () {
    function ConsequenceRosterTableConfig() {
    }
    ConsequenceRosterTableConfig.columns = [
        {
            id: 0,
            name: 'Select All',
            className: 'mfe-beh-smart-groups-consequence_roster-column-flex-1 mfe-beh-smart-groups-consequence_roster-column-value',
        },
        {
            id: 1,
            name: 'Student name',
            className: 'mfe-beh-smart-groups-consequence_roster-column-flex-3 mfe-beh-smart-groups-consequence_roster-column-value',
        },
        {
            id: 2,
            name: 'Grade',
            className: 'mfe-beh-smart-groups-consequence_roster-column-flex-2 mfe-beh-smart-groups-consequence_roster-column-value',
        },
        {
            id: 3,
            name: 'Positivity ratio',
            className: 'mfe-beh-smart-groups-consequence_roster-column-flex-3 mfe-beh-smart-groups-consequence_roster-column-value',
        },
        {
            id: 4,
            name: 'Dollar/Pts',
            className: 'neon-2_3_0-table-simple-cell-is-numeric mfe-beh-smart-groups-consequence_roster-column-flex-2 mfe-beh-smart-groups-consequence_roster-column-value',
        },
        {
            id: 5,
            name: 'Reason',
            className: 'mfe-beh-smart-groups-consequence_roster-column-dollar-merit mfe-beh-smart-groups-consequence_roster-column-value',
        },
        {
            id: 6,
            name: 'Date',
            className: 'mfe-beh-smart-groups-consequence_roster-column-dollar-merit mfe-beh-smart-groups-consequence_roster-column-value',
        }, {
            id: 7,
            name: 'Details',
            className: 'mfe-beh-smart-groups-consequence_roster-column-flex-1 mfe-beh-smart-groups-consequence_roster-column-value',
        }
    ];
    ConsequenceRosterTableConfig.pageLength = 20;
    return ConsequenceRosterTableConfig;
}());
export { ConsequenceRosterTableConfig };
// Define sorting directions
export var SORT_ASC = 'asc';
export var SORT_DESC = 'desc';
export var SORT_ASC_ICON = 'sort-carets-up|rtl-flip';
export var SORT_DESC_ICON = 'sort-carets-down|rtl-flip';
export var SORT_DEFAULT_ICON = 'sort-outline|rtl-flip';
export var ADD_STUDENT_NOTES_SAVE_TOOLTIP_DISABLE = 'Enter a note before saving';
export var MAX_NOTES_VALUE = 300;
export var DownloadModalHeader = 'Caution';
export var DownloadModalContent = "This file contains personal student information. \nDo not share the file or send it via insecure channels such as email. \nDelete the file as soon as it is no longer needed.";
export var errorMsgForDownload = function (smartGroupName, type) { return "Smart group ".concat(smartGroupName, " was not exported to a ").concat(type.toUpperCase(), " file. Please try again."); };
export var successMsgForDownload = function (smartGroupName, type) { return "Smart group ".concat(smartGroupName, " was successfully exported to a ").concat(type.toUpperCase(), " file."); };
export var ExportMsgHeading = 'Export';
export var formatStudents = function (students) {
    var formattedStudents = {};
    students.forEach(function (student) {
        var key = "".concat(student.student_id, "_").concat(student.student_enrollment_id);
        if (!formattedStudents[key]) {
            formattedStudents[key] = {
                id: key,
                consequence_roster_ids: [],
                assigned_date: student.assigned_date,
                formatted_assigned_date: student.formatted_assigned_date,
                reasons: '',
                consequence_id: student.consequence_id,
                student_enrollment_id: student.student_enrollment_id,
                student_id: student.student_id,
                grade_id: student.grade_id,
                school_id: student.school_id,
                first_name: student.first_name,
                last_name: student.last_name,
                ordinal: student.ordinal,
                behaviors: student.behaviors ? __spreadArray([], student.behaviors, true) : [],
                positivity: student.positivity,
                dollarPts: student.dollarPts,
                full_name: student.full_name,
                grade: student.grade
            };
        }
        formattedStudents[key].consequence_roster_ids.unshift(student.id);
        student.reasons ? formattedStudents[key].reasons = "".concat(student.reasons, ", ").concat(formattedStudents[key].reasons) : null;
    });
    // Convert the formatted data object to an array
    var formattedStudentsArray = Object.values(formattedStudents);
    return formattedStudentsArray;
};
export var filterStudents = function (consequenceType, originalData, filterSelectedGrades, filterFromDate, filterToDate) {
    if (consequenceType === void 0) { consequenceType = ConsequenceType.Continuous; }
    // Filtering only for 'continuous' consequence type because for 'fixed day' consequence type, we are getting the filtered data from the API itself
    if (consequenceType === ConsequenceType.Continuous && originalData.length && filterSelectedGrades.length && filterFromDate && filterToDate) {
        return __spreadArray([], originalData.filter(function (student) { return (filterSelectedGrades.includes(String(student.grade_id)) && DateHelper.getDate(student.assigned_date) >= filterFromDate && DateHelper.getDate(student.assigned_date) <= filterToDate); }), true);
    }
    return originalData;
};
export var isStudentDataInSelectedFixedConsequenceFilter = function (addedStudentData, filterContext) {
    // Checking if the added student data is in the selected fixed consequence filter data
    return DateHelper.getDate(addedStudentData.assigned_date).valueOf() === filterContext.filterRosterDate.valueOf() && filterContext.filterSelectedGrades.includes(String(addedStudentData.grade_id));
};
