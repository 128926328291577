import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RootComponent } from './root-component';
console.log('This file is for running your MFE stand alone. Note: this file will not be rendered via module federation.');
var container = document.getElementById('react-app-injection');
container.setAttribute('neon-react-root', '');
var root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(React.createElement(BrowserRouter, null,
    React.createElement(RootComponent, null)));
