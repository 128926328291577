import { DateHelper } from './helper/date-helper';
var ApiUrls = /** @class */ (function () {
    function ApiUrls() {
    }
    var _a, _b;
    ApiUrls.SmartGroupApi = (_a = process.env.API_URL) !== null && _a !== void 0 ? _a : '';
    ApiUrls.DashboardApi = (_b = process.env.DASHBOARD_API) !== null && _b !== void 0 ? _b : '';
    return ApiUrls;
}());
export { ApiUrls };
var InitialValues = /** @class */ (function () {
    function InitialValues() {
    }
    InitialValues.SchoolYearData = {
        loading: true,
        id: undefined,
        name: undefined,
        start_date: undefined,
        end_date: undefined
    };
    InitialValues.GroupsAndStudentsContext = {
        loading: true,
        groupsAndStudents: []
    };
    InitialValues.GradesContext = {
        loading: true,
        grades: [],
    };
    InitialValues.FilterContext = {
        filterSelectedGrades: [],
        filterFromDate: DateHelper.getDate(),
        filterToDate: DateHelper.getDate(),
        filterRosterDate: DateHelper.getDate(),
        todayDate: DateHelper.getDate(),
        setFilterContext: function (selectedGrades, fromDate, toDate) { return undefined; }
    };
    InitialValues.AuthContext = {
        authToken: '',
        timeZone: '',
        analysisTimePeriod: {
            startDate: '',
            endDate: '',
        }
    };
    return InitialValues;
}());
export { InitialValues };
var SidePanelView = /** @class */ (function () {
    function SidePanelView() {
    }
    SidePanelView.Settings = 'settings';
    SidePanelView.AddStudent = 'add-student';
    SidePanelView.ViewStudent = 'view-student';
    return SidePanelView;
}());
export { SidePanelView };
var ConsequenceType = /** @class */ (function () {
    function ConsequenceType() {
    }
    ConsequenceType.Continuous = 'continuous';
    ConsequenceType.Fixed = 'fixed day';
    return ConsequenceType;
}());
export { ConsequenceType };
export var StartValue = 'Start';
export var EndValue = 'End';
/**
 * Returns the validation message for continuous dates.
 * @param dateType - The type of date ('Start' or 'End').
 * @returns The validation message.
 */
export function getContinuousDateValidationMessage(dateType) {
    return "".concat(dateType, " date must be ").concat(dateType === 'End' ? 'after' : 'before', " ").concat(dateType === 'End' ? 'start' : 'end', " date");
}
/**
 * Returns a validation error message for the school year start date.
 * @param dateType - The type of date ('Start' or 'End').
 * @returns The validation error message.
 */
export function getSchoolYearStartDateValidationError(dateType) {
    return "".concat(dateType, " date must be on or after school year start date");
}
/**
 * Returns a validation error message for the school year end date.
 * @param dateType - The type of date ('Start' or 'End').
 * @returns The validation error message.
 */
export function getSchoolYearEndDateValidationError(dateType) {
    return "".concat(dateType, " date must be on or before school year end date");
}
