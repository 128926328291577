/* eslint-disable @typescript-eslint/no-unused-vars */
import { superFetch } from '@ps-refarch-ux/mfe-utils';
import { ApiUrls, ConsequenceType } from '../../common/constants';
var SmartGroup = /** @class */ (function () {
    function SmartGroup() {
    }
    SmartGroup.prototype.getSmartGroup = function (authToken, _then, _catch) {
        var apiUrl = "".concat(ApiUrls.SmartGroupApi, "/consequence-roster?apiCall=smartgroups");
        superFetch(apiUrl, function (response) {
            _then(response.response.data);
        }, { headers: { 'Content-Type': 'application/json', 'Authorization': authToken } }, function (error) {
            console.log("SmartGroup|getSmartGroup - The following error occured : ".concat(error));
            return _catch(error);
        });
    };
    SmartGroup.prototype.getSmartGroupStudentList = function (authToken, smartGroupId, consequenceType, rosterDate, grades, analysisTimePeriod, _then, _catch) {
        if (consequenceType === void 0) { consequenceType = ConsequenceType.Continuous; }
        var queryParams = "&startDate=".concat(analysisTimePeriod.startDate, "&endDate=").concat(analysisTimePeriod.endDate);
        if (consequenceType === ConsequenceType.Fixed) {
            queryParams += "&assignedDate=".concat(rosterDate, "&gradeId=").concat(grades);
        }
        var apiUrl = "".concat(ApiUrls.SmartGroupApi, "/consequence-roster?apiCall=list&consequenceId=").concat(smartGroupId).concat(queryParams);
        superFetch(apiUrl, function (response) {
            _then(response.response.data);
        }, { headers: { 'Content-Type': 'application/json', 'Authorization': authToken } }, function (error) {
            console.log("SmartGroup|getSmartGroupStudentList - The following error occured : ".concat(error));
            return _catch(error);
        });
    };
    SmartGroup.prototype.getGroupsAndStudentsAndSchoolYear = function (authToken, _then, _catch) {
        var apiUrl = "".concat(ApiUrls.SmartGroupApi, "/consequence-roster?apiCall=groupsandstudentsandschoolyear");
        superFetch(apiUrl, function (response) {
            _then(response.response.data.groups_and_students, response.response.data.school_year[0]);
        }, { headers: { 'Content-Type': 'application/json', 'Authorization': authToken } }, function (error) {
            console.log("SmartGroup|getGroupsAndStudentsAndSchoolYear - The following error occured : ".concat(error));
            return _catch(error);
        });
    };
    SmartGroup.prototype.getGrades = function (authToken, _then, _catch) {
        var apiUrl = "".concat(ApiUrls.DashboardApi, "/dashboard?apiCall=grades");
        superFetch(apiUrl, function (response) {
            var _a, _b;
            _then((_b = (_a = response.response) === null || _a === void 0 ? void 0 : _a.data[0]) === null || _b === void 0 ? void 0 : _b.data);
        }, { headers: { 'Content-Type': 'application/json', 'Authorization': authToken } }, function (error) {
            console.log("SmartGroup|(Dashboard)getGrade - The following error occured : ".concat(error));
            return _catch(error);
        });
    };
    SmartGroup.prototype.getStudentsNotesAndBehaviors = function (authToken, studentIds, smartGroupId, analysisTimePeriod, _then, _catch) {
        var apiUrl = "".concat(ApiUrls.SmartGroupApi, "/consequence-roster?apiCall=viewdetails&consequenceId=").concat(smartGroupId, "&studentId=").concat(studentIds, "&startDate=").concat(analysisTimePeriod.startDate, "&endDate=").concat(analysisTimePeriod.endDate);
        superFetch(apiUrl, function (response) {
            _then(response.response.data);
        }, { headers: { 'Content-Type': 'application/json', 'Authorization': authToken } }, function (error) {
            console.log("SmartGroup|getStudentsNotesAndBehaviors - The following error occured : ".concat(error));
            return _catch(error);
        });
    };
    SmartGroup.prototype.addStudent = function (authToken, requestData, _then, _catch) {
        var apiUrl = "".concat(ApiUrls.SmartGroupApi, "/consequence-roster?apiCall=add-student");
        var init = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            },
            method: 'POST',
            body: JSON.stringify(requestData)
        };
        superFetch(apiUrl, function (response) {
            var _a, _b;
            if (response.statusCode >= 400) {
                _catch((_a = response.response.data) === null || _a === void 0 ? void 0 : _a.message);
            }
            else {
                _then(response.response.message, (_b = response.response.data) === null || _b === void 0 ? void 0 : _b.data[0]);
            }
        }, init);
    };
    SmartGroup.prototype.markCompleted = function (authToken, requestData, _then, _catch) {
        var apiUrl = "".concat(ApiUrls.SmartGroupApi, "/consequence-roster?apiCall=markcomplete");
        var init = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            },
            method: 'POST',
            body: JSON.stringify(requestData)
        };
        superFetch(apiUrl, function (response) {
            if (response.statusCode >= 400) {
                _catch();
            }
            else {
                _then();
            }
        }, init);
    };
    SmartGroup.prototype.deleteStudent = function (authToken, requestData, _then, _catch) {
        var apiUrl = "".concat(ApiUrls.SmartGroupApi, "/consequence-roster?apiCall=deleteconsequence");
        var init = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            },
            method: 'DELETE',
            body: JSON.stringify(requestData)
        };
        superFetch(apiUrl, function (response) {
            if (response.statusCode >= 400) {
                _catch();
            }
            else {
                _then();
            }
        }, init);
    };
    SmartGroup.prototype.addStudentNotes = function (authToken, requestData, _then, _catch) {
        var apiUrl = "".concat(ApiUrls.SmartGroupApi, "/consequence-roster?apiCall=addnotes");
        var init = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            },
            method: 'POST',
            body: JSON.stringify(requestData)
        };
        superFetch(apiUrl, function (response) {
            if (response.statusCode >= 400) {
                _catch(response.response.message);
            }
            else {
                _then(response.response.message);
            }
        }, init);
    };
    SmartGroup.prototype.exportSmartGroupData = function (authToken, type, smartGroupId, consequenceType, filterContext, _then, _catch) {
        if (consequenceType === void 0) { consequenceType = ConsequenceType.Continuous; }
        var optionalQueryParams = '';
        if (consequenceType === ConsequenceType.Fixed) {
            optionalQueryParams = "&assignedDate=".concat(filterContext.filterRosterDate.toDateString());
        }
        else if (consequenceType === ConsequenceType.Continuous) {
            optionalQueryParams = "&fromDate=".concat(filterContext.filterFromDate.toDateString(), "&toDate=").concat(filterContext.filterToDate.toDateString());
        }
        var apiUrl = "".concat(ApiUrls.SmartGroupApi, "/consequence-roster?apiCall=export&type=").concat(type, "&consequenceId=").concat(smartGroupId, "&gradeId=").concat(filterContext.filterSelectedGrades).concat(optionalQueryParams);
        superFetch(apiUrl, function (response) {
            var _a;
            _then((_a = response.response) === null || _a === void 0 ? void 0 : _a.data.data);
        }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': authToken } }, function (error) {
            console.log("Fetch error - The following error occured : ".concat(error));
            return _catch(error);
        });
    };
    return SmartGroup;
}());
export var SmartGroupApi = new SmartGroup();
