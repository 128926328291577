var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/**
 * SmartGroupTable Component
 *
 * The `SmartGroupTable` component is a React component that represents a table used to display and manage students in a smart group.
 * It provides features such as sorting, pagination, and actions (mark completed, add notes, delete).
 *
 * @component
 *
 * @param {Object} props - The component props.
 * @param {string} props.consequenceType - The type of consequence ('continuous' or 'fixed').
 * @param {number} props.consequenceId - The ID of the consequence.
 * @param {string} props.consequenceName - The name of the consequence.
 *
 * @returns {ReactElement} The rendered `SmartGroupTable` component.
 *
 */
import React, { useContext, useRef, useState, useEffect } from 'react';
import { NeonMenu, NeonButton, NeonLastUpdated, NeonIcon, NeonCheckboxSingle, NeonProgressIndicator, NeonPagination, NeonSystemMessage, showSystemMessage, DEFAULT_SYSTEM_MESSAGES_FLOATING_ID, NeonEllipsis } from '@ps-refarch-ux/neon';
import { getContinuousActionsOptions, getFixedActionsOptions, ConsequenceRosterTableConfig, SORT_ASC, SORT_DESC, sortTableData, SORT_ASC_ICON, SORT_DESC_ICON, SORT_DEFAULT_ICON, formatStudents, filterStudents, isStudentDataInSelectedFixedConsequenceFilter, exportSmartGroupData } from './smart-group-table-config';
import LoadingState from '../../../common/components/loading-state';
import './smart-group-table.scss';
import { addStudent, deleteStudent, markCompleted, processStudentNames } from '../../../common/components/helper/common-message-helper';
import { SmartGroupApi } from '../../../services/api/smart-groups-mfe-api';
import { AuthContext } from '../../../common/context/auth-context';
import ErrorState from '../../../common/components/error-state';
import ConsequenceRosterSidePanel from '../../../common/components/consequence-roaster-side-panel';
import ModalLoader from '../../../common/components/modal-loader';
import { FilterContext, GradesContext, ModalLoaderContext, SchoolYearContext, SidePanelViewContext } from '../../../common/context/smart-group-context';
import ModalDialog from '../../../common/components/modal-dialog';
import { ConsequenceType, SidePanelView } from '../../../common/constants';
import SmartGroupAddNotes from './smart-group-add-notes';
import DownloadSmartGroupDetails from './download-smartgroup-details';
import { DateHelper } from '../../../common/helper/date-helper';
import EmptyState from '../../../common/components/empty-state';
export var getBehaviorViewData = function (data, studentId) {
    var behaviorData = data.find(function (element) { return element.student_id === studentId; });
    return (behaviorData && behaviorData.behaviors) || [];
};
export default function SmartGroupTable(_a) {
    var consequenceType = _a.consequenceType, consequenceId = _a.consequenceId, consequenceName = _a.consequenceName;
    var _b = useContext(AuthContext), authToken = _b.authToken, timeZone = _b.timeZone, analysisTimePeriod = _b.analysisTimePeriod;
    var filterContext = useContext(FilterContext);
    var gradesContext = useContext(GradesContext);
    var schoolYearContext = useContext(SchoolYearContext);
    //useRef for lastUpdated
    var lastUpdated = useRef(DateHelper.getMomentDateWithTimeZone(timeZone));
    //for modal loader open and close
    var modalLoaderContext = useContext(ModalLoaderContext);
    //for side panel view open and close
    var sidePanelViewContext = useContext(SidePanelViewContext);
    //for modal dialog open and close
    var _c = useState(''), modalDialog = _c[0], setModalDialog = _c[1];
    var consequenceIdRef = useRef(consequenceId);
    //for table
    var _d = useState({ column: '', direction: '' }), sortConfig = _d[0], setSortConfig = _d[1];
    var _e = useState(true), tableLoading = _e[0], setTableLoading = _e[1];
    var _f = useState({
        studentId: '',
        studentName: '',
        consequenceId: consequenceId,
    }), selectedStudentInfo = _f[0], setSelectedStudentInfo = _f[1];
    var _g = useState(false), tableError = _g[0], setTableError = _g[1];
    //state for view behavior data.
    var _h = useState([]), viewBehaviorData = _h[0], setViewBehaviorData = _h[1];
    var _j = useState(false), refreshTableToggle = _j[0], setRefreshTableToggle = _j[1];
    var _k = useState(false), showInlineSystemMessage = _k[0], setShowInlineSystemMessage = _k[1];
    var _l = useState(null), systemMessageTimeoutId = _l[0], setSystemMessageTimeoutId = _l[1];
    var smartGroupsTableData = useRef([]);
    var _m = useState(false), addNotesModalView = _m[0], setAddNotesModalView = _m[1];
    var _o = useState([]), originalData = _o[0], setOriginalData = _o[1];
    //state for export data :- store as an object with key as type and value as data.
    var _p = useState(false), exportData = _p[0], setExportData = _p[1];
    var _q = useState(''), exportDataType = _q[0], setExportDataType = _q[1];
    //for pagination
    var _r = useState(1), currentPage = _r[0], setCurrentPage = _r[1];
    var _s = useState([]), paginatedData = _s[0], setPaginatedData = _s[1];
    //states for pagination ends
    var tableRef = useRef(null);
    var _t = useState(new Map()), selectedStudents = _t[0], setSelectedStudents = _t[1]; // Map to store selected student info (id, name)
    var setTable = function (tableDate) {
        setSortConfig({ column: '', direction: '' });
        setCurrentPage(1);
        smartGroupsTableData.current = __spreadArray([], tableDate, true);
        setPaginatedData(__spreadArray([], tableDate, true).slice(0, ConsequenceRosterTableConfig.pageLength));
        setTableError(false);
        setTableLoading(false);
    };
    // Updating the last updated time to change the last updated component
    var updateLastUpdated = function () {
        lastUpdated.current = DateHelper.getMomentDateWithTimeZone(timeZone);
    };
    /**
     * Action start
     */
    var actionConfirmHandler = function (type, selectedStudents) {
        var extractedNames = processStudentNames(selectedStudents, true);
        modalLoaderContext.setModalLoaderView(type);
        setModalDialog('');
        var consequenceRosterdetails = [];
        selectedStudents.forEach(function (selectedStudent) {
            consequenceRosterdetails.push({
                'consequence_roster_ids': selectedStudent.consequenceRosterIds,
                'student_id': Number(selectedStudent.studentId),
                'student_enrollment_id': Number(selectedStudent.studentEnrollmentId)
            });
        });
        var requestBody = {
            'consequence_id': Number(consequenceId),
            'consequence_roster_details': consequenceRosterdetails
        };
        if (type === 'mark-completed') {
            SmartGroupApi.markCompleted(authToken, requestBody, function () {
                // Success call back
                ActionConfirmResponseHandler(type, true, extractedNames);
            }, function () {
                // Error call back
                ActionConfirmResponseHandler(type, false, extractedNames);
            });
        }
        else if (type === 'delete-student') {
            SmartGroupApi.deleteStudent(authToken, requestBody, function () {
                // Success call back
                ActionConfirmResponseHandler(type, true, extractedNames);
            }, function () {
                // error call back
                ActionConfirmResponseHandler(type, false, extractedNames);
            });
        }
    };
    //This function will be called after we got the Action API response
    var ActionConfirmResponseHandler = function (type, success, extractedNames) {
        if (type === 'mark-completed') {
            if (success) {
                // If mark complete is success then we will remove those studentsin the table and show success system msg
                removeDeletedOrMarkedCompletedStudents();
                showSystemMessage(markCompleted.successBody(extractedNames, consequenceName), {
                    sendTo: DEFAULT_SYSTEM_MESSAGES_FLOATING_ID,
                    messageType: 'success',
                    title: markCompleted.NotificationHeader,
                });
                // updating the last updated time
                updateLastUpdated();
            }
            else {
                // If add student API call is failed then we will show error system msg
                showSystemMessage(markCompleted.errorBody(extractedNames, consequenceName), {
                    sendTo: DEFAULT_SYSTEM_MESSAGES_FLOATING_ID,
                    messageType: 'error',
                    title: markCompleted.NotificationHeader,
                });
            }
        }
        else if (type === 'delete-student') {
            if (success) {
                removeDeletedOrMarkedCompletedStudents();
                showSystemMessage(deleteStudent.successBody(extractedNames, consequenceName), {
                    sendTo: DEFAULT_SYSTEM_MESSAGES_FLOATING_ID,
                    messageType: 'success',
                    title: deleteStudent.NotificationHeader,
                });
                // updating the last updated time
                updateLastUpdated();
            }
            else {
                // If add student API call is failed then we will show error system msg
                showSystemMessage(deleteStudent.errorBody(extractedNames, consequenceName), {
                    sendTo: DEFAULT_SYSTEM_MESSAGES_FLOATING_ID,
                    messageType: 'error',
                    title: deleteStudent.NotificationHeader,
                });
            }
        }
        // setting modalLoaderView to empty values to close modal loader.
        modalLoaderContext.setModalLoaderView('');
    };
    var removeDeletedOrMarkedCompletedStudents = function () {
        var selectedStudentsIds = Array.from(selectedStudents.keys());
        var updatedOriginalData = originalData.filter(function (student) { return !selectedStudentsIds.includes(student.id); });
        setOriginalData(__spreadArray([], updatedOriginalData, true));
        setTable(updatedOriginalData);
        setSelectedStudents(new Map());
    };
    /**
     * Action end
     */
    /**
     * Add Student start
     */
    //This function will be called after we got the Add Student API response
    var addStudentConfirmResponseHandler = function (success, studentName, message, addedStudentdata) {
        if (studentName === void 0) { studentName = 'Student'; }
        if (success) {
            // add student API call is success and we received the added student data then we will add the student in the table and then show success system msg
            if (addedStudentdata) {
                var updatedOriginalData = __spreadArray([], originalData, true);
                // If the consequence type is continuous or if the consequence type is fixed and the added student is in the selected filter then only we will add the student in the table
                if (consequenceType === ConsequenceType.Continuous || (consequenceType === ConsequenceType.Fixed && isStudentDataInSelectedFixedConsequenceFilter(addedStudentdata, filterContext))) {
                    // Formatting the added student data
                    addedStudentdata['consequence_roster_ids'] = [Number(addedStudentdata.id)];
                    addedStudentdata['id'] = "".concat(addedStudentdata.student_id, "_").concat(addedStudentdata.student_enrollment_id);
                    addedStudentdata.reasons ? addedStudentdata.reasons = "".concat(addedStudentdata.reasons, ", ") : null;
                    // Checking wether added student already existed in the table or not (can happen in fixed consequence type)
                    var indexToRemove = updatedOriginalData.findIndex(function (student) { return student.id === addedStudentdata.id; });
                    // If added student already existed then modify the existing student data and add the new consequence roster id and reason field
                    if (indexToRemove !== -1) {
                        var existingStudent = updatedOriginalData.splice(indexToRemove, 1);
                        existingStudent[0].consequence_roster_ids.unshift(addedStudentdata.consequence_roster_ids[0]);
                        addedStudentdata.reasons ? existingStudent[0].reasons = "".concat(existingStudent[0].reasons).concat(addedStudentdata.reasons) : null;
                        updatedOriginalData = __spreadArray([existingStudent[0]], updatedOriginalData, true);
                    }
                    else {
                        // If added student is not existed then add the student in the table
                        updatedOriginalData = __spreadArray([addedStudentdata], updatedOriginalData, true);
                    }
                }
                setOriginalData(__spreadArray([], updatedOriginalData, true)); // updating the original data
                // now setting filtered data for the table according to the filter
                var filteredStudents = filterStudents(consequenceType, updatedOriginalData, filterContext.filterSelectedGrades, filterContext.filterFromDate, filterContext.filterToDate);
                setTable(filteredStudents);
            }
            showSystemMessage(addStudent.successBody(studentName, consequenceName), {
                sendTo: DEFAULT_SYSTEM_MESSAGES_FLOATING_ID,
                messageType: 'success',
                title: addStudent.NotificationHeader,
            });
            // updating the last updated time
            updateLastUpdated();
        }
        else {
            // If add student API call is failed then we will show error system msg
            if (message === 'Student already in Continuous Roster.') {
                showSystemMessage(addStudent.alreadyAddedBody(studentName, consequenceName), {
                    sendTo: DEFAULT_SYSTEM_MESSAGES_FLOATING_ID,
                    messageType: 'error',
                    title: addStudent.NotificationHeader,
                });
            }
            else {
                showSystemMessage(addStudent.errorBody(studentName, consequenceName), {
                    sendTo: DEFAULT_SYSTEM_MESSAGES_FLOATING_ID,
                    messageType: 'error',
                    title: addStudent.NotificationHeader,
                });
            }
        }
        // setting modalLoaderView to empty values to close modal loader.
        modalLoaderContext.setModalLoaderView('');
    };
    /**
     * Add Student end
     */
    /**
     * Student Table Start
     */
    //useEffect for Table data fetching.
    useEffect(function () {
        setPaginatedData([]);
        smartGroupsTableData.current = [];
        setOriginalData([]);
        setTableError(false);
        setTableLoading(true);
        // if we have the grades and school year data then only we will fetch the student list data because we need to filter according to the grades and dates
        if (!gradesContext.loading && !schoolYearContext.loading) {
            // if changing to fixed consequence type then we will set the filter roster date as current date
            // Using consequenceIdRef to check if the consequenceId is changed or not because this useEffect will also be called when we apply the filter for fixed consequence (i.e refreshTableToggle is changed and we dont want to reset the filter roster date in that case)
            var filterRosterDate = filterContext.filterRosterDate;
            if (consequenceId !== consequenceIdRef.current && consequenceType === ConsequenceType.Fixed) {
                filterRosterDate = filterContext.todayDate;
                filterContext.setFilterContext(filterContext.filterSelectedGrades, filterContext.filterFromDate, filterContext.filterToDate, filterRosterDate);
                consequenceIdRef.current = consequenceId;
            }
            SmartGroupApi.getSmartGroupStudentList(authToken, consequenceId, consequenceType, filterRosterDate.toDateString(), filterContext.filterSelectedGrades, analysisTimePeriod, function (studentListData) {
                var formattedStudents = formatStudents(studentListData[0].students);
                setOriginalData(formattedStudents); // updating the original data
                // now setting filtered data for the table according to the filter
                var filteredStudents = filterStudents(consequenceType, formattedStudents, filterContext.filterSelectedGrades, filterContext.filterFromDate, filterContext.filterToDate);
                setTable(filteredStudents);
                // updating the last updated time
                updateLastUpdated();
            }, function (error) {
                setTableLoading(false);
                setTableError(true);
            });
        }
    }, [consequenceId, refreshTableToggle, gradesContext.loading, schoolYearContext.loading]);
    var handleTableRefresh = function () {
        setTableLoading(true);
        setTableError(false);
        setRefreshTableToggle(!refreshTableToggle);
    };
    // Handle sorting
    var handleSortColumnClick = function (column) {
        var newSortDirection = sortConfig.column === column && sortConfig.direction === SORT_ASC ? SORT_DESC : SORT_ASC;
        var newSortConfig = { column: column, direction: newSortDirection };
        setSortConfig(newSortConfig);
        var sortedData = sortTableData(smartGroupsTableData.current, newSortConfig.column, newSortConfig.direction);
        smartGroupsTableData.current = sortedData;
        // Update paginated data without changing the current page
        var startIndex = (currentPage - 1) * ConsequenceRosterTableConfig.pageLength;
        var endIndex = startIndex + ConsequenceRosterTableConfig.pageLength;
        setPaginatedData(sortedData.slice(startIndex, endIndex));
    };
    var onClickofAddNotes = function () {
        if (selectedStudents.size === 0) {
            showInlineSystemMessageOnActionClick();
        }
        else {
            setAddNotesModalView(true);
        }
    };
    var onClickofMarkComplete = function () {
        if (selectedStudents.size === 0) {
            showInlineSystemMessageOnActionClick();
        }
        else {
            setModalDialog('mark-completed');
        }
    };
    var onClickofDelete = function () {
        if (selectedStudents.size === 0) {
            showInlineSystemMessageOnActionClick();
        }
        else {
            setModalDialog('delete-student');
        }
    };
    var showInlineSystemMessageOnActionClick = function () {
        setShowInlineSystemMessage(true);
        // Clear the previous timeout if it exists
        if (systemMessageTimeoutId !== null) {
            clearTimeout(systemMessageTimeoutId);
        }
        var newTimeout = window.setTimeout(function () {
            setShowInlineSystemMessage(false);
        }, 2000);
        setSystemMessageTimeoutId(newTimeout);
    };
    var noOfPages = (smartGroupsTableData.current && smartGroupsTableData.current.length > 0) ? Math.ceil(smartGroupsTableData.current.length / ConsequenceRosterTableConfig.pageLength) : 0;
    /**
     * Student Table End
     */
    // csv export function.
    var onClickOfExportSmartGroup = function (type) {
        setExportDataType(type);
        setExportData(true);
    };
    /**
     * Settings start
     */
    var settingsApplyHandler = function (type, grades, fromDate, toDate) {
        if (type === ConsequenceType.Continuous) {
            var filteredOriginalData = filterStudents(type, originalData, grades, fromDate, toDate);
            setTable(filteredOriginalData);
        }
        else {
            handleTableRefresh();
        }
    };
    /**
     * Settings end
     */
    return (React.createElement("div", null,
        (modalLoaderContext.modalLoaderView) && React.createElement(ModalLoader, null),
        sidePanelViewContext.sidePanelView && React.createElement(ConsequenceRosterSidePanel, { viewBehaviorData: viewBehaviorData, selectedStudentInfo: selectedStudentInfo, addStudentConfirmResponseHandler: addStudentConfirmResponseHandler, settingsApplyHandler: settingsApplyHandler, consequenceId: consequenceId, consequenceType: consequenceType }),
        (exportData) && React.createElement(DownloadSmartGroupDetails, { authToken: authToken, setExportData: setExportData, smartGroupName: consequenceName, exportDataType: exportDataType, smartGroupIdForDownload: consequenceId, filterContext: filterContext, consequenceType: consequenceType }),
        (modalDialog) && React.createElement(ModalDialog, { type: modalDialog, setModalDialog: setModalDialog, selectedStudents: selectedStudents, actionConfirmHandler: actionConfirmHandler, consequenceName: consequenceName }),
        addNotesModalView && selectedStudents && React.createElement(SmartGroupAddNotes, { setAddNotesModalView: setAddNotesModalView, selectedStudents: selectedStudents, smartGroupId: consequenceId, authToken: authToken, updateLastUpdated: updateLastUpdated }),
        React.createElement("div", { className: 'mfe-beh-smart-groups-table-header' },
            React.createElement("div", { className: 'mfe-beh-smart-groups-table-header-actions' },
                React.createElement("div", null,
                    React.createElement(NeonMenu, { id: "mfe-beh-smart-groups-smart-groups-actions", dataHeaderText: "Actions", dataShowHeaderText: "false", "data-testid": consequenceType === 'continuous' ? 'mfe-beh-smart-groups-actions_menu_continuous' : 'mfe-beh-smart-groups-actions_menu_fixed', options: consequenceType === 'continuous' ? getContinuousActionsOptions(onClickofMarkComplete, onClickofAddNotes, onClickofDelete) : getFixedActionsOptions(onClickofAddNotes, onClickofDelete) },
                        React.createElement("div", { "data-slot": "popper-trigger" },
                            React.createElement(NeonButton, { id: "mfe-beh-smart-groups-smart-groups-actions-btn-menu", "data-testid": consequenceType === 'continuous' ? 'mfe-beh-smart-groups-smart-groups-actions-btn-menu-continuous' : 'mfe-beh-smart-groups-smart-groups-actions-btn-menu-fixed', dataText: "Actions", dataIcon: "caret-down", dataIconPosition: "right" })))),
                React.createElement("div", { className: 'mfe-beh-smart-groups-table-header-buttons' },
                    React.createElement("div", null,
                        React.createElement(NeonButton, { id: "mfe-beh-smart-groups-add-stu", dataType: "secondary", dataText: "Add student", onClick: function (event) {
                                event.preventDefault();
                                sidePanelViewContext.setSidePanelView(SidePanelView.AddStudent);
                            } }))),
                React.createElement("div", { className: 'mfe-beh-smart-groups-table-header-buttons' },
                    React.createElement(NeonMenu, { id: "mfe-beh-smart-groups-smart-groups-export", dataHeaderText: "Export", "data-testid": 'mfe-beh-smart-groups-smart-groups-export-menu', dataShowHeaderText: false, options: exportSmartGroupData(onClickOfExportSmartGroup) },
                        React.createElement("div", { "data-slot": "popper-trigger" },
                            React.createElement(NeonButton, { id: "mfe-beh-smart-groups-smart-groups-actions-btn", dataText: "Export", dataIcon: "caret-down", dataIconPosition: "right" }))))),
            React.createElement("div", { className: 'mfe-beh-smart-groups-last-setting' },
                React.createElement("span", null,
                    React.createElement(NeonLastUpdated, { dataColor: 'dark', dataDate: lastUpdated.current.format('MM/DD/YYYY'), dataTime: lastUpdated.current.format('HH:mm'), dataTimeZoneString: lastUpdated.current.format('z') })),
                React.createElement("span", { className: 'mfe-beh-smart-groups-line' }, "|"),
                React.createElement(NeonButton, { id: "mfe-beh-smart-groups-settings-wheel", dataType: "utility", dataIcon: "filter", dataText: "Utility", onClick: function () { sidePanelViewContext.setSidePanelView(SidePanelView.Settings); } }))),
        showInlineSystemMessage && React.createElement("div", { className: 'mfe-beh-smart-groups-system-message-container' },
            React.createElement(NeonSystemMessage, { id: "mfe-beh-smart-groups-system-message-info-actions", dataPosition: "inline", className: 'mfe-beh-smart-groups-system-message-action' },
                React.createElement("p", { style: { width: '100%' }, "data-slot": "message-content" }, "Select one or more students to perform an action."))),
        React.createElement("div", { className: 'mfe-beh-smart-groups-consequence_table' },
            React.createElement("table", { ref: tableRef, className: 'neon-2_3_0-table-simple neon-2_3_0-table-responsive-container-xl mfe-beh-smart-groups-consequence_roster_table' },
                React.createElement("thead", null,
                    React.createElement("tr", null, ConsequenceRosterTableConfig.columns.map(function (column, columnIndex) {
                        var isSortable = column.name === 'Student name' || column.name === 'Grade' || column.name === 'Date';
                        var isSelectAll = column.name === 'Select All';
                        return (React.createElement("th", { className: column.className, key: columnIndex, onClick: isSortable ? (function () { return handleSortColumnClick(column.name.toLowerCase()); }) : undefined }, isSelectAll ? (React.createElement("div", { className: 'mfe-beh-smart-groups-planet-all-checkbox-list-parent' },
                            React.createElement(NeonCheckboxSingle, { id: "mfe-beh-smart-groups-planet-all-checkbox-list", dataLabelText: 'Select all students', dataShowLabel: true, value: selectedStudents.size === paginatedData.length, onInput: function (e) {
                                    var newSelectedStudentsInfo = new Map(selectedStudents);
                                    if (e.target.checked) {
                                        paginatedData.forEach(function (student) {
                                            newSelectedStudentsInfo.set(student.id, {
                                                'studentName': student.full_name,
                                                'studentId': student.student_id,
                                                'studentEnrollmentId': student.student_enrollment_id,
                                                'consequenceRosterIds': student.consequence_roster_ids
                                            });
                                        });
                                    }
                                    else {
                                        paginatedData.forEach(function (student) {
                                            newSelectedStudentsInfo.delete(student.id);
                                        });
                                    }
                                    setSelectedStudents(newSelectedStudentsInfo);
                                } }))) : (React.createElement("div", { "data-testid": "".concat(column.id, "-").concat(column.name) }, column.name === 'Student name' || column.name === 'Grade' || column.name === 'Date' ? (React.createElement(React.Fragment, null,
                            column.name,
                            React.createElement("span", null,
                                React.createElement(NeonIcon, { dataIcon: sortConfig.column === column.name.toLowerCase() ? (sortConfig.direction === SORT_ASC ? SORT_ASC_ICON : SORT_DESC_ICON) : SORT_DEFAULT_ICON, dataSize: "x-small" })))) : (column.name)))));
                    }))),
                paginatedData && paginatedData.length > 0 && React.createElement("tbody", null, paginatedData.map(function (rowData, index) {
                    return (React.createElement("tr", { key: index },
                        React.createElement("td", { "data-label": 'Select Feild' },
                            React.createElement("div", { className: 'mfe-beh-smart-groups-planet-all-checkbox-list-parent' },
                                React.createElement(NeonCheckboxSingle, { id: "mfe-beh-smart-groups-planet-all-checkbox-list-".concat(index), dataLabelText: "Student ".concat(rowData.full_name), dataShowLabel: true, value: selectedStudents.has(rowData.id), onInput: function (e) {
                                        var newSelectedStudentsInfo = new Map(selectedStudents);
                                        e.target.checked
                                            ? newSelectedStudentsInfo.set(rowData.id, {
                                                'studentName': rowData.full_name,
                                                'studentId': rowData.student_id,
                                                'studentEnrollmentId': rowData.student_enrollment_id,
                                                'consequenceRosterIds': rowData.consequence_roster_ids
                                            })
                                            : newSelectedStudentsInfo.delete(rowData.id);
                                        // add setState like this to avoid stale data
                                        setSelectedStudents(function (prev) {
                                            return newSelectedStudentsInfo;
                                        });
                                    }, "data-testid": "mfe-beh-smart-groups-planet-all-checkbox-list-".concat(index) }))),
                        React.createElement("td", { "data-label": 'Student name' }, rowData.full_name),
                        React.createElement("td", { "data-label": 'Grade' }, rowData.ordinal),
                        React.createElement("td", { "data-label": 'Positivity ratio' },
                            React.createElement("div", { className: 'mfe-beh-smart-groups-ct_positivity' },
                                React.createElement("span", null,
                                    rowData.positivity,
                                    "% positivity"),
                                React.createElement(NeonProgressIndicator, { id: "mfe-beh-smart-groups-ct_positivity-".concat(index), dataLayout: "bar-only", value: rowData.positivity, dataTotal: 100, dataColor: 'green', dataBarHeight: 'small' }))),
                        React.createElement("td", { className: "neon-2_3_0-table-simple-cell-is-numeric", "data-label": 'Dollar/Pts' }, rowData.dollarPts),
                        React.createElement("td", { "data-label": 'Reason' },
                            React.createElement(NeonEllipsis, { id: 'mfe-beh-smart-groups-reasons' + index, dataLineClamp: 1, dataShowTooltip: true, dataPopperPlacement: (rowData.reasons.length > 0 && rowData.reasons.length < 64) ? 'top-start' : rowData.reasons.length > 300 ? 'right' : 'top' }, rowData.reasons.replace(/,\s*$/, ''))),
                        React.createElement("td", { "data-label": 'Date' }, rowData.assigned_date),
                        React.createElement("td", { "data-label": 'View', "data-testid": "".concat(rowData.full_name, "-").concat(index) },
                            React.createElement(NeonButton, { id: "mfe-beh-smart-groups-button_view".concat(index), dataTooltipText: "View Details", dataType: "borderless", dataText: "View", onClick: function (e) {
                                    e.preventDefault();
                                    setViewBehaviorData(getBehaviorViewData(smartGroupsTableData.current, rowData.student_id));
                                    setSelectedStudentInfo({
                                        studentId: rowData.student_id,
                                        studentName: rowData.full_name,
                                        consequenceId: consequenceId,
                                    });
                                    sidePanelViewContext.setSidePanelView(SidePanelView.ViewStudent);
                                } }))));
                }))),
            tableLoading && React.createElement("div", { className: 'mfe-beh-smart-groups-smart-group-table-loader-state' },
                React.createElement(LoadingState, null),
                " "),
            tableError &&
                React.createElement("div", { className: 'mfe-beh-smart-groups-smart-group-table-error-state' },
                    React.createElement(ErrorState, { type: 'content', onRefreshHandler: handleTableRefresh })),
            !tableLoading && !tableError && smartGroupsTableData.current && !smartGroupsTableData.current.length &&
                React.createElement("div", { className: 'mfe-beh-smart-groups-smart-group-table-empty-state' },
                    React.createElement(EmptyState, { type: 'students' })),
            smartGroupsTableData.current && smartGroupsTableData.current.length !== 0 && noOfPages > 1 && React.createElement("div", { className: 'mfe-beh-smart-groups-pagination-parent' },
                React.createElement(NeonPagination, { id: "mfe-beh-smart-groups-pagination-data", dataArrayLength: smartGroupsTableData.current.length, dataCurrentPage: currentPage, dataPageLength: ConsequenceRosterTableConfig.pageLength, dataShowSinglePage: "false", neonPaginationPageChange: function (newPage, getPaginatedData) {
                        setCurrentPage(newPage);
                        setPaginatedData(getPaginatedData(smartGroupsTableData.current));
                        setSelectedStudents(new Map());
                    }, dataShowPageSizeSelect: false, dataShowJumpTo: true, dataShowResults: true })))));
}
