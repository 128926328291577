import React from 'react';
import { NeonLoadingIndicator } from '@ps-refarch-ux/neon';
import './loading-state.scss';
export default function LoadingState() {
    return (React.createElement("div", { className: 'mfe-beh-smart-groups-loading-state' },
        React.createElement(NeonLoadingIndicator, { dataSize: "x-large" }),
        React.createElement("div", { className: 'mfe-beh-smart-groups-loading-text' },
            React.createElement("div", null, "Smart groups content is loading."),
            React.createElement("div", null, "This may take a few seconds"))));
}
